import styled from 'styled-components'
import { Box } from 'rebass/styled-components'
import GatsbyImage from 'gatsby-image'

export const LoadingImage = styled.img`
  border-top-left-radius: ${props => props.theme.content.borderRadius};
  border-top-right-radius: ${props => props.theme.content.borderRadius};
  height: 100%;
  width: 100%;
  transition: 1s all linear;
  object-fit: fill;
  z-index: 1;

  &.loaded {
    opacity: 0;
  }
`

export const Image = styled.img`
  border-top-left-radius: ${props => props.theme.content.borderRadius};
  border-top-right-radius: ${props => props.theme.content.borderRadius};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  transition: 0.2s all ease-in-out;
  opacity: 0;
  z-index: 2;

  &.loaded {
    opacity: 1;
  }
`

export const Wrapper = styled(Box)`
  overflow: hidden;
  padding: 0;
  position: relative;
  margin: -3rem -6rem 1rem;

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin: -1rem;
  }
`

export const Img = styled(GatsbyImage)`
  border-top-left-radius: ${props => props.theme.content.borderRadius};
  border-top-right-radius: ${props => props.theme.content.borderRadius};
  margin: -3rem -6rem 1rem;

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    margin: -1rem;
  }
`
