import React, { Fragment, FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { Img } from '../components/FeatureImage/styled'
import Seo from '../components/Seo'
import { PostQuery } from '../graphqlTypes'
import Layout from '../components/Layout'
import ContentBox from '../components/ContentBox'

export const pageQuery = graphql`
  query Post($id: String) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      frontmatter {
        slug
        tags
        title
        date
        headerImageAlt
        headerImage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

interface Props {
  data: PostQuery
}

export const Post: FunctionComponent<Props> = ({ data: { markdownRemark: post } }: Props) => {
  const fluidHeaderImage = post?.frontmatter?.headerImage?.childImageSharp?.fluid as FluidObject
  return (
    <Fragment>
      <Seo title={post?.frontmatter?.title}/>
      <Layout>
        <ContentBox>
          { fluidHeaderImage !== null ? <Img fluid={fluidHeaderImage} alt={post?.frontmatter?.headerImageAlt as string} /> : ''}
          <h1>{ post?.frontmatter?.title }</h1>
          <div dangerouslySetInnerHTML={{ __html: post?.html as string }} />
        </ContentBox>
      </Layout>
    </Fragment>
  )
}

export default Post
